import React, { useEffect } from "react";
import "./AboutPage.scss";
import { Link, useNavigate } from "react-router-dom";
import logo from "../HomePage/miske_logo.png";
import dreamy from "../HomePage/dreamy_design.png";
import filterDesign from "../HomePage/filter_design.png";
import productDesign from "../HomePage/product_design.png";

const AboutPage = () => {
   

    return (
        <div className="about_us_container">
            
            <div className="hero_section">
                <h1 className="title_hero">I'm Miske. A Front-End developer with experience in Shopify Theme Development</h1>
                <div className="skills">
                    
                    <span>THEME DEVELOPMENT</span>
                    <img src={logo} alt="" />
                    <span>SHOPIFY</span>
                    <img src={logo} alt="" />
                    <span>THEME CUSTOMIZATION</span>
                </div>
            </div>

            <img src={dreamy} alt="" className="project_img" />
            <img src={filterDesign} alt="" className="project_img" />
            <img src={productDesign} alt="" className="project_img" />

            <img src={logo} alt="" className="logo_"/>
            <span className="title_section">LINKS</span>

            <span className="subtitle_section">EMAIL : miskelvilaly@gmail.com</span>
            <span className="subtitle_section">UPWORK : <Link target="_blank" rel="noreferrer" to="https://www.upwork.com/freelancers/~012b6c322ce952794f" className="link">Miske</Link> </span>

        </div>   
    );
};

export default AboutPage;
