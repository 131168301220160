import React from "react";
import "./HomePage.scss";
import logo from "./miske_logo.png";
import dev_stack from "./dev_stack.svg";
import bloomy from "./bloomy_design.png";
import torch from "./design_torch.png";
import dreamy from "./dreamy_design.png";
import filterDesign from "./filter_design.png";
import productDesign from "./product_design.png";
import tableDesign from "./table_design.png";
import PorfolioCard from "../../Components/PortfolioCard/PorfolioCard";

type Props = {};

const HomePage = (props: Props) => {
  return (
    <> 
        <div className="home_container">

            <div className="hero_section">
                <h1 className="title_hero">I'm Miske. A Front-End developer with experience in Shopify Theme Development</h1>
                <div className="skills">
                    
                    <span>THEME DEVELOPMENT</span>
                    <img src={logo} alt="" />
                    <span>SHOPIFY</span>
                    <img src={logo} alt="" />
                    <span>THEME CUSTOMIZATION</span>
                </div>
            </div>

            <div className="banner_animation_skills">
                <div className="inner_wrapper">
                <div className="track_bar">
                        <span>SEO</span>
                        <img src={logo} alt="" />
                    </div>
                    <div className="track_bar">
                        <span>CRO</span>
                        <img src={logo} alt="" />
                    </div>
                    <div className="track_bar">
                        <span>THEME DEVELOPMENT</span>
                        <img src={logo} alt="" />
                    </div>
                    <div className="track_bar">
                        <span>THEME CUSTOMIZATION</span>
                        <img src={logo} alt="" />
                    </div>
                    <div className="track_bar">
                        <span>CUSTOM SHOPIFY FEATURES</span>
                        <img src={logo} alt="" />
                    </div>
                    <div className="track_bar">
                        <span>WEB DEV</span>
                        <img src={logo} alt="" />
                    </div>
                    <div className="track_bar">
                        <span>STORE OPTIMIZATION</span>
                        <img src={logo} alt="" />
                    </div>
                   
                    
                </div>
            </div>
            <div className="portfolio_section">
                <img src={logo} alt="" />
                <span className="title_section">Portfolio</span>

                <span className="subtitle_section">SHOPIFY THEMES</span>
                <div className="protfolio_cards">
                    <PorfolioCard password="11223300" logo={torch} type="SHOPIFY THEME" paragraph="Torch is 100% custom made.It's a fully-loaded Shopify theme equipped with a custom mega menu, custom smooth animations, custom Bundling products." link="/project/torch" isImageLeft={true} url="https://torch-theme.myshopify.com/"/>
                    <PorfolioCard password="gahldi" logo={bloomy} type="SHOPIFY THEME" paragraph="Bloomy is 100% custom Theme and Fully Customizable in the theme editor.It's a fully-loaded Shopify theme equipped with a mega menu, Custom Drawer Animation, Custom Filters, Custom Product Page that have many Sections to customize." link="/project/bloomy" isImageLeft={false} url="https://bbs20221.myshopify.com/"/>
                    <PorfolioCard password="" logo={dreamy} type="SHOPIFY THEME" paragraph="Robo-bay's Apple like animation is fully custom animation made from scratch. Robo-bay reach out to me for creating one of the most complex animations i have ever seen and it was fully implemented to the pixel. Please Scroll down to see the animations." link="/project/robobay" isImageLeft={true} url="https://robo-bay.com/products/dreame-l20-ultra-robot-vacuum-and-mop-with-mop-extend"/>
                </div>

                <span className="subtitle_section">SHOPIFY CUSTOM SECTIONS</span>
                <div className="protfolio_cards">
                    <PorfolioCard password="gahldi" logo={filterDesign} type="SHOPIFY CUSTOM SECTION" paragraph="The Filter section is a fully custom section that can filter products based on color, type or both." link="/project/bloomy-filter" isImageLeft={false} url="https://bbs20221.myshopify.com/"/>
                    <PorfolioCard password="gahldi" logo={productDesign} type="SHOPIFY CUSTOM SECTION" paragraph="This Product Section is 100% custom made with interesting feature linking products based on color and type." link="/project/bloomy-product" isImageLeft={true} url="https://bbs20221.myshopify.com/products/black-silk-pillowcase-zippered"/>
                    <PorfolioCard  password="gahldi" logo={tableDesign} type="SHOPIFY CUSTOM SECTION" paragraph="This Table was created from scratch and 100% customizable in the Theme Editor." link="/project/bloomy-table" isImageLeft={false} url="https://bbs20221.myshopify.com/products/black-silk-pillowcase-zippered"/>
                </div>
            </div>
            <div className="my_info_card">
                <div className="left_container">
                    <div className="name">
                        <h5>MISKE</h5>
                        <img src={logo} alt="" />
                        <h5>EL VILALY</h5>
                    </div>
                    <h1 className="role">SHOPIFY DEVELOPER</h1>
                </div>
                <div className="right_container">
                    <p className="top_paragraph">As a Shopify developer, my mastery of SCSS, CSS, and vanilla JavaScript empowers me to swiftly bring innovative features to life. With proficiency in Liquid, I seamlessly merge design with functionality, crafting captivating storefronts and optimizing user experiences.</p>
                    <p className="bottom_paragraph"> I handle Liquid with ease, seamlessly integrating design and functionality. It's like building a puzzle where every piece fits perfectly, ensuring smooth navigation for online shoppers. Whether it's refining storefronts or optimizing performance, I thrive on the technical challenges of the job.</p>
                    <h5 className="experience_title">EXPERIENCE</h5>
                    <h5 className="years">4+ Years</h5>
                </div>
            </div>
            <div className="dev_stack">
                <h5 className="title_dev_stack"><span>My Shopify Dev Stack</span></h5>
                <div className="img_dev_stack">
                    <img src={dev_stack} alt="" />
                </div>
            </div>
            <div className="skills_cards">
                <img src={logo} alt="" />
                <span className="title_section">Skills</span>
                <span className="subtitle_section">SHOPIFY DEV SKILLS</span>

                <div className="cards_skills_container">
                    
                    <div className="card_skill">
                        <span className="title_section">CRO</span>
                        <span className="subtitle_section">
I excel in conversion rate optimization (CRO), leveraging data-driven insights and user-centric strategies to optimize sales and enhance user experiences.</span>
                    </div>
                  
                    <div className="card_skill">
                        <span className="title_section">STORE SPEED OPTIMIZATION</span>
                        <span className="subtitle_section">
                        I excel in store speed optimization, enhancing loading times and performance for seamless user experiences.</span>
                    </div>
                
                </div>
              
                <div className="cards_skills_container">
                    
                    <div className="card_skill">
                        <span className="title_section">SEO</span>
                        <span className="subtitle_section">I specialize in SEO optimization, crafting strategies to boost website visibility and rankings. </span>
                    </div>
                  
                    <div className="card_skill">
                        <span className="title_section">CUSTOM FEATURES</span>
                        <span className="subtitle_section">
                        I swiftly create custom features, bypassing the need for Shopify apps, utilizing SCSS, CSS, vanilla JavaScript, and Liquid to seamlessly integrate and enhance user experience.</span>
                    </div>
                
                </div>
            
            </div>
        </div>   
    </>
  );
};

export default HomePage;