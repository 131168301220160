import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import logo from "./miske.png";
import more from "./menu.png";
import miske_logo from "../../Pages/HomePage/miske_logo.png";
interface Props {}

const Navbar = (props: Props) => {
    const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNavVisibility = () => {
    const navLinks = document.querySelector('.nav_links');
    if (navLinks) {
      navLinks.classList.toggle('visible');
      setIsNavVisible(!isNavVisible);
    }
  };
  
  return (
    <nav className="nav_container">
        <Link className="logo_link" to="/"><img src={miske_logo} alt="" /></Link>
        <div className="nav_links">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
        </div>
        <div className="more_btn_container" onClick={toggleNavVisibility}><img src={more} alt="" /></div>
        <Link to="/">Let's Talk</Link>
    </nav>
  );
};

export default Navbar;