import React, { createContext, useContext, useState } from "react";

interface ProjectContextType {
  paragraph: string;
  url: string;
  password: string;
  img:string;
  type:string;
  setProjectInfo: (paragraph: string,url: string, password: string,img:string,type:string) => void;
}

const ProjectContext = createContext<ProjectContextType>({
  paragraph: "",
  url:"",
  password: "",
  img:"",
  type:"",
  setProjectInfo: () => {},
});

export const useProjectContext = () => useContext(ProjectContext);

interface ProjectProviderProps {
  children: React.ReactNode;
}

export const ProjectProvider: React.FC<ProjectProviderProps> = ({ children }) => {
  const [paragraph, setParagraphName] = useState("");
  const [url, setUrlName] = useState("");
  const [password, setPassword] = useState("");
  const [img, setImg] = useState("");
  const [type, setType] = useState("");

  const setProjectInfo = (paragraph: string,url: string, password: string,img:string,type:string) => {
    setParagraphName(paragraph);
    setUrlName(url);
    setPassword(password);
    setImg(img);
    setType(type);
  };

  return (
    <ProjectContext.Provider
      value={{
        paragraph,
        url,
        password,
        img,
        type,
        setProjectInfo,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
