import React, { useEffect } from "react";
import "./ProjectPage.scss";
import { Link, useNavigate } from "react-router-dom";
import { useProjectContext } from "./ProjectContext";

const ProjectPage = () => {
    const { paragraph, url, password, img,type } = useProjectContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!img) {
            navigate("/");
        }
    }, [img, navigate]);

    if (!img) {
        return null; // or you can render a loading message or component
    }

    return (
        <div className="project_page_container">
            <h1 className="title_section">{type.toUpperCase()}</h1>
            <h5 className="paragraph">{paragraph}</h5>
            <img src={img} alt="" className="project_img" />
            <h4 className="password">THEME PASSWORD: {password}</h4>
            <div className="launch_project">
                <Link to={url} target="_blank" rel="noreferrer">LAUNCH PROJECT</Link>
            </div>
        </div>   
    );
};

export default ProjectPage;
