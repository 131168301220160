import React from "react";
import { Link } from "react-router-dom";
import "./PorfolioCard.scss";
import { useProjectContext } from "../../Pages/ProjectPage/ProjectContext";

interface Props {
  logo: string;
  type: string;
  paragraph: string;
  isImageLeft: boolean;
  link: string;
  password:string;
  url:string;
}

const PorfolioCard = (props: Props) => {
  // Define styles based on the value of isImageLeft
  const containerStyles: React.CSSProperties = {
    flexDirection: props.isImageLeft ? "row" : "row-reverse"
  };
  const { setProjectInfo } = useProjectContext();

  const handleProjectClick = () => {
    setProjectInfo(props.paragraph,props.url,props.password,props.logo,props.type);
  };
  return (
    <div className="portfolio_card_container" style={containerStyles}>
      <div className="image_desc_container">
        <img src={props.logo} alt="" className="desc_img"/>
      </div>
      <div className="desc_container">
        <h1 className="title_desc">{props.type}</h1>
        <p className="paragraph_desc">{props.paragraph}</p>
        <Link to={props.link} className="link_desc" onClick={handleProjectClick}>Read More</Link>
      </div>
    </div>
  );
};

export default PorfolioCard;
