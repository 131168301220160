import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import HomePage from "../Pages/HomePage/HomePage";
import ProjectPage from "../Pages/ProjectPage/ProjectPage";
import AboutPage from "../Pages/AboutPage/AboutPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "", element: <HomePage /> },
      { path: "about", element: <AboutPage /> },
      { path: "contact", element: <AboutPage /> },
      { path: "project/:projectName", element: <ProjectPage /> }, 
    ],
  },
]);
